import Vue from 'vue'
import VueRouter from 'vue-router'
import home from '../components/home.vue'
import login from '../components/login.vue'
import signup from '../components/signup.vue'
import store from "../store/index"


Vue.use(VueRouter)

async function beforeCheckAuthentication() {
  await store.dispatch('tryAutoLogin'); 
  // chekck if user is Authenticated
    if(store.getters.isAuthenticated){
      return true
    }
    else{
      return false
    }
}

const routes = [
  {
    path: '/',
    name: 'Home',
    component: home,
    beforeEnter: async (to, from, next) => {
      beforeCheckAuthentication().then(res => { 
        return res===true ? next() : next('/login')
    })}
  },
  {
    path: '/login',
    name: 'login',
    component: login
  },
  {
    path: '/signup',
    name: 'signup',
    component: signup
  },
]

const router = new VueRouter({
  routes
})

export default router
