<template>
  <v-container fluid>
    <v-row no-gutters align="start" justify="start">
      <v-col cols="4">
        <div class="headline font-weight-bold">Pricing Pie</div>
      </v-col>
    </v-row>

    <v-row no-gutters align="start" justify="center" style=" margin-top: 20%;">
      <v-col cols="3">
        <v-form>
          <v-text-field
            v-model="email"
            label="Email"
            outlined
            color="black"
            background-color="#FFFFFF"
            :rules="[rules.required, rules.email]"
          ></v-text-field>
          <v-text-field
            v-model="password"
            :append-icon="isPassShow ? 'mdi-eye' : 'mdi-eye-off'"
            :rules="[rules.required]"
            :type="isPassShow ? 'text' : 'password'"
            label="Password"
            outlined
            color="black"
            background-color="#FFFFFF"
            @click:append="isPassShow = !isPassShow"
          ></v-text-field>
          <v-btn block depressed large color="primary" @click="onSubmit()">
            LOG IN
          </v-btn>
        </v-form>
        <div class="subtitle-1 mt-5" align="center" color="secondary">
          Don’t have an account? <router-link to="/signup">Sign up for free</router-link>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      email: "",
      password: "",
      errorMessage: "",
      isPassShow: false,
      rules: {
        required: (value) => !!value || "Required.",
        email: (value) => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Invalid e-mail.";
        },
      },
    };
  },
  components: {},
  created() {},
  computed: {},
  methods: {
    onSubmit() {
      this.$store
        .dispatch("login", {
          email: this.email,
          password: this.password,
        })
        .then((x) => {
          console.log("hier : ", x);
        });
    },
  },
};
</script>
