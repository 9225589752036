import Vue from "vue";
import Vuex from "vuex";
import axios from 'axios'
import router from '../router/index'
import jwt_decode from 'jwt-decode'

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    token: null,
    userId: null,
    user: null,
  },
  mutations: {
    authUser(state, userData) {
      state.token = userData.token;
      state.userId = userData.userId;
      state.user = userData.user;
    },
    storeUser(state, user) {
      state.user = user;
    },
    clearAuthData(state) {
      state.token = null;
      state.userId = null;
    },
  },
  actions: {
    setLogoutTimer({ commit }, expirationTime) {
      // Timeout in: expirationTime
      setTimeout(() => {
        commit("clearAuthData");
      }, expirationTime);
    },
    login({ commit, dispatch }, authData) {
      return axios.post("/accounts/api/v1/login/", {
        username: "admin1admin",
        email: authData.email,
        password: authData.password,
      })
        .then((res) => {
          if (res.status === 200) {
            localStorage.setItem("token", res.data.access_token);
            var decoded = jwt_decode(res.data.access_token );
            const expirationDate = new Date(decoded.exp * 1000);
            localStorage.setItem("userId", decoded.id);
            localStorage.setItem("expirationDate", expirationDate);
            axios.defaults.headers.common["Authorization"] = res.data.access_token;
            commit("authUser", {
              token: res.data.access_token,
              userId: decoded.id,
              user: decoded.name,
            });
            dispatch("setLogoutTimer", expirationDate);
            router.replace("/");
            return res;
          }
        })
        .catch((error) => {
          console.log("Login-Fehler:", error);
          return error;
        });
    },
    tryAutoLogin({ commit }) {
      // check if user has Token
      const token = localStorage.getItem("token");
      axios.defaults.headers.common["Authorization"] = "JWT " + token;
      if (!token) {
        return; 
      }
      // check if Timeout has not expired
      const expirationDate = new Date(localStorage.getItem("expirationDate"));
      const now = new Date();
      if (now >= expirationDate) {
        commit("clearAuthData");
        localStorage.removeItem("expirationDate");
        localStorage.removeItem("token");
        localStorage.removeItem("userId");
        return;
      }
      const userId = localStorage.getItem("userId");
      var decoded = jwt_decode(token);
      // autologin: decoded
      commit("authUser", {
        token: token,
        userId: userId,
        user: decoded.name,
      });
    },
    logout({ commit }) {
      commit("clearAuthData");
      localStorage.removeItem("expirationDate");
      localStorage.removeItem("token");
      localStorage.removeItem("userId");
      router.replace("/login");
    },
  },
  modules: {},
  getters: {
    user(state) {
      return state.user;
    },
    userId(state) {
      return state.userId;
    },
    isAuthenticated(state) {
      return state.token !== null;
    },
  },
});