import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

import colors from 'vuetify/lib/util/colors'
import '@mdi/font/css/materialdesignicons.css'

Vue.use(Vuetify);


export default new Vuetify({
    theme: {
      themes: {
        light: {
          primary: colors.indigo, // #3F51B5
          secondary: colors.indigo.lighten-3, // #FFCDD2
          accent: colors.grey.darken-2, // #616161
        },
      },
    },
  })