<template>
  <v-navigation-drawer color="primary" dark :mini-variant.sync="mini">
    <v-list-item>
      <v-list-item-content>
        <v-list-item-title class="headline font-weight-bold">
          Pricing Pie
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <v-divider></v-divider>

    <v-list dense nav>
      <v-list-item v-for="item in items" :key="item.title" link>
        <v-list-item-icon>
          <v-icon>{{ item.icon }}</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>{{ item.title }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
    <template v-slot:append>
      <v-list dense nav>
        <v-list-item link @click="logout()">
          <v-list-item-icon>
            <v-icon>mdi-logout</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>logout</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <div class="text-right">
        <v-btn icon @click.stop="mini = !mini">
          <v-icon v-if="!mini">mdi-arrow-left</v-icon>
          <v-icon v-else>mdi-arrow-right</v-icon>
        </v-btn>
      </div>
    </template>
  </v-navigation-drawer>
</template>

<script>
export default {
  data() {
    return {
      mini: false,
      items: [
        { title: "Import Data", icon: "mdi-swap-vertical" },
        { title: "History", icon: "mdi-history" },
        { title: "My rules", icon: "mdi-select-group" },
        { title: "rule Overview", icon: "mdi-chart-donut-variant" },
        { title: "Single item Analyser", icon: "mdi-chart-bar" },
        { title: "My Profile", icon: "mdi-account-box" },
      ],
    };
  },
  methods: {
    logout() {
      this.$store.dispatch("logout");
    },
  },
};
</script>
